import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { nodeName } from "jquery"
import Slider from "react-slick"
import "../styles/slick.min.css"
import "../styles/slick-theme.min.css"
import Img from "gatsby-image"
const CaseStudiesHeader = props => {
  const blogdata = props.data.allMarkdownRemark.edges
  const data = blogdata
  var testimonailSettings = {
    dots: false,
    speed: 900,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    margin: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <React.Fragment>
      {data ? (
        <div className="col-md-12 text-center">
          <h2 className="h1">Case Studies</h2>
        </div>
      ) : (
        ""
      )}
      {data.map((blog, index) => {
        const title = blog.node.frontmatter.title || blog.node.fields.slug
        return (
          index < 3 && (
            <div className="col-md-4">
              <Link to={"/case-studies" + blog.node.fields.slug}>
                <div
                  className="row aligner"
                  style={{
                    boxShadow: "0 3px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "15px",
                    margin: "10px",
                  }}
                >
                  <div className="col-md-6" style={{ padding: "0" }}>
                    <Img
                      fluid={
                        blog.node.frontmatter.featuredImage.childImageSharp
                          .fluid
                      }
                      style={{ borderRadius: "16px", margin: "10px" }}
                      className="img-responsive"
                      title={title}
                      alt={title}
                    />
                  </div>
                  <div className="col-md-6" style={{ padding: "0 20px" }}>
                    <h5
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#303030",
                      }}
                      title={title}
                    >
                      {title}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          )
        )
      })}
    </React.Fragment>
  )
}

//return(<h1></h1>)

const CaseStudies = props => {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
              fileAbsolutePath: { regex: "/(case-studies)/" }
              frontmatter: { templateKey: { eq: "case-study" } }
            }
          ) {
            edges {
              node {
                excerpt
                fields {
                  slug
                }
                frontmatter {
                  date 
                  title
                  author
                  category
                  tags
                  keyword
                  featuredImage {
                    relativeDirectory
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
            totalCount
          }
        }
      `}
      render={data => <CaseStudiesHeader data={data} filter={props.filter} />}
    />
  )
}

export default CaseStudies
